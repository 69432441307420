import React, { Component } from 'react';
import Logo from "../assets/logo.png";

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div className="bg">
        <img src={Logo} alt="Logo"/>
      </div>
    );
  }
}
